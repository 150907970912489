import React from 'react'
import "./Hero.sass"
import SearchInput from './SearchInput';
import SearchList from './SearchList';
import "./SearchBar.sass"
import "./SearchBarCorrect.sass"

const Hero = (props) => {

    const handleClickScroll = () => {
        const element = document.querySelector("#favouriteLocations")
        if (element) {
            setTimeout(() => {
                element.scrollIntoView({ behavior: 'smooth' })
            }, 50)
        }
    };

    return (
        <div className="Hero" style={{ height: "calc(100vh - 104px)" }}>
            <div className="container pt-5 bck" style={{ height: "calc(100vh - 104px)" }}>

                <div className="SearchBar">
                    <h1>Weather app</h1>
                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eligendi, itaque nihil nemo voluptatem sunt dicta voluptates quisquam numquam consequatur eos natus. Maxime sed deleniti consequatur modi similique? Non, iste placeat.</p>

                </div>
                <div className="SearchBarCorrect">
                    <SearchInput handleInputOnChange={props.handleInputOnChange} searchInput={props.searchInput.value} />
                    <SearchList data={props.data} resetCityInput={props.resetCityInput} handleInputOnChange={props.handleInputOnChange} handleButtonClick={props.handleButtonClick} handleAddToFavourites={props.handleAddToFavourites} />
                </div>
                <button onClick={() => handleClickScroll()} className='btn btn-primary favourites'>Go to favourites</button>
            </div>



        </div >);

}

export default Hero