import { useState, useEffect, useMemo, createContext } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import Header from './components/Header';
import FavouriteLocations from "./components/FavouriteLocations";
/* import Footer from './components/Footer'; */
import cities from "./cities.json"
import Hero from "./components/Hero";
import SearchedWeather from "./components/SearchedWeather";
import "./other.sass"

import axios from "axios";

/* import Modal from "./components/Modal"; */

/* import "./variables.sass" */

/* import { createClient } from 'pexels'; */

export const WeatherCodeContext = createContext()


function App() {
  const [citiesData, setCitiesData] = useState(cities)


  const [currentLocation, setCurrentLocation] = useState({ latitude: "", longitude: "" })
  const [currentLocationWeather, setCurrentLocationWeather] = useState()
  /* currentLocationWeather - timezone*/

  const [urlApi, setUrlApi] = useState()
  const [searchInput, setSearchInput] = useState({ value: "" })
  const [city, setCity] = useState(false)
  const [weatherData, setWeatherData] = useState()
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingLocalWeather, setLoadingLocalWeather] = useState(false)

  const [favouritesLocations, setFavouritesLocations] = useState([])
  const [favLocComplete, setFavLocComplete] = useState([])

  const [memoSearch, setMemoSearch] = useState("")

  const [localTime, setLocalTime] = useState()

  const citiesMemo = useMemo(() => {
    let arr = []

    if (memoSearch.length > 2) {
      return cities.filter((city) => {
        return (city.city.toLowerCase().includes(memoSearch.toLowerCase()) || city.country.toLowerCase().includes(memoSearch.toLowerCase()))
      })
    }
    else return arr
  }, [memoSearch, cities])


  const handleClickScroll = () => {
    const element = document.querySelector(".searchedWeather")
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth' })
      }, 50)
    }
  };


  //getCurrentLocation
  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
      setLoadingLocalWeather(true)
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }

  const showPosition = (position) => {

    setCurrentLocation({ latitude: position.coords.latitude, longitude: position.coords.longitude })
  }

  function searchByCity(query, data) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].city.toLowerCase() === query.toLowerCase()) {
        return data[i];
      }
    }
    return null; // if no match found
  }

  const getCurrentLocationWeather = () => {
    /*     console.log("getCurrentLocationWeather")
        console.log(currentLocationWeather.timezone); */

    const str = currentLocationWeather.timezone;
    const parts = str.split('/');
    const result = parts[parts.length - 1];

    /* console.log(searchByCity(result, cities)) */

    setCity(searchByCity(result, cities))

    setUrlApi(`https://api.open-meteo.com/v1/forecast?latitude=${currentLocation.latitude}&longitude=${currentLocation.longitude}&daily=weathercode,temperature_2m_max,temperature_2m_min,sunrise,sunset&current_weather=true&timezone=auto&hourly=temperature_2m,relativehumidity_2m,precipitation,apparent_temperature,windspeed_10m`)
    handleClickScroll()
  }

  const handleButtonClick = (element) => {
    console.log(element);
    setCity(element)

    const { lat, lng } = element
    setUrlApi(`https://api.open-meteo.com/v1/forecast?latitude=${lat}&longitude=${lng}&daily=weathercode,temperature_2m_max,temperature_2m_min,sunrise,sunset&current_weather=true&timezone=auto&hourly=temperature_2m,relativehumidity_2m,precipitation,apparent_temperature,windspeed_10m`)
  }


  const handleAddToFavourites = (city) => {
    /* setFavouritesLocations(locations => ([
      ...locations, element])) */

    console.log(city)

    const citiesLocalStorage = JSON.parse(localStorage.getItem("citiesLocalStorage")) || [];
    const isDuplicate = citiesLocalStorage.some(c => c.lat === city.lat && c.lng === city.lng);
    if (!isDuplicate) {
      citiesLocalStorage.push(city);
      localStorage.setItem("citiesLocalStorage", JSON.stringify(citiesLocalStorage));
    }

    const jsonString = localStorage.getItem('citiesLocalStorage');

    // Convert JSON string to object
    const myObject = JSON.parse(jsonString);
    console.log("localStorage", myObject)
    setFavouritesLocations(myObject)

  }

  useEffect(() => {
    const jsonString = localStorage.getItem('citiesLocalStorage');
    const myObject = JSON.parse(jsonString);
    setFavouritesLocations(myObject)
  }, [])

  const handleRemoveFavourite = (city) => {
    console.log("Delete: ", city)

    const citiesLocalStorage = JSON.parse(localStorage.getItem("citiesLocalStorage")) || [];
    console.log(citiesLocalStorage)

    const index = citiesLocalStorage.findIndex(c => c.lat === city.singleLocation.lat && c.lng === city.singleLocation.lng);
    if (index !== -1) {
      citiesLocalStorage.splice(index, 1);
      localStorage.setItem("citiesLocalStorage", JSON.stringify(citiesLocalStorage));
      setFavouritesLocations(citiesLocalStorage);
      console.log("deleted")
    }
  }


  useEffect(() => {

    if (favouritesLocations) {

      favouritesLocations.forEach(singleLocation => {
        fetch(`https://api.open-meteo.com/v1/forecast?latitude=${singleLocation.lat}&longitude=${singleLocation.lng}&daily=weathercode,temperature_2m_max,temperature_2m_min,sunrise,sunset&current_weather=true&timezone=auto&hourly=temperature_2m,relativehumidity_2m,precipitation,apparent_temperature,windspeed_10m`)
          .then(response => response.json())
          .then(data => {
            let merged = { singleLocation, data };
            if (!favLocComplete.some(obj => obj.singleLocation.lat === singleLocation.lat && obj.singleLocation.lng === singleLocation.lng)) {
              setFavLocComplete(prevState => [...prevState, merged]);
            } else {
              setFavLocComplete(prevState => prevState.map(obj => {
                if (obj.singleLocation.lat === singleLocation.lat && obj.singleLocation.lng === singleLocation.lng) {
                  return merged;
                } else {
                  return obj;
                }
              }));
            }
          });
      });

      // Remove deleted favourite location from favLocComplete
      const deletedFavLoc = favLocComplete.filter(obj => !favouritesLocations.some(loc => loc.lat === obj.singleLocation.lat && loc.lng === obj.singleLocation.lng));
      if (deletedFavLoc.length > 0) {
        setFavLocComplete(prevState => prevState.filter(obj => !deletedFavLoc.includes(obj)));
      }

    }
    else {
      console.log("You have no favourite location!")
    }

  }, [favouritesLocations]);


  //FETCH TO SINGLE DETAIL
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch(
          urlApi
        );
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        let actualData = await response.json();
        setWeatherData(actualData);
        setError(null);
        /* console.log(actualData); */
      } catch (err) {
        setError(err.message);
        setWeatherData(null);
      } finally {
        setLoading(false);
      }
    }
    getData()

  }, [urlApi])


  function padTimeString(timeString) {
    const timeParts = timeString.split(":");

    let hours = timeParts[0];
    if (hours.length === 1) {
      hours = hours.padStart(2, "0"); // Pad the hour with leading zero if it's a single digit
    }

    let minutes = timeParts[1];
    if (minutes.length === 1) {
      minutes = minutes.padStart(2, "0"); // Pad the minute with leading zero if it's a single digit
    }

    return `${hours}:${minutes}`;
  }

  /* HEROKU */
  /* useEffect(() => {
    const corsprefix = `https://cors-anywhere.herokuapp.com/`
    const fetchUrl = `https://www.timeapi.io/api/Time/current/coordinate?latitude=${city.lat}&longitude=${city.lng}`

    async function fetchData() {
      try {

        const response = await axios.get(corsprefix + fetchUrl);
        console.log(response.data)
        let currentTime = `${response.data?.hour}:${response.data?.minute}`
        setLocalTime(padTimeString(currentTime))


      } catch (error) {
        console.error(error);
      }
    }

    fetchData();

  }, [city]) */

  //FETCH SINGLE LOCATION
  useEffect(() => {

    async function fetchData() {
      try {
        const response = await axios.get(`https://api.open-meteo.com/v1/forecast?latitude=${currentLocation.latitude}&longitude=${currentLocation.longitude}&daily=weathercode,sunrise,sunset&current_weather=true&timezone=auto`);
        setCurrentLocationWeather(response.data);
        setLoadingLocalWeather(false);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();

  }, [currentLocation.latitude])


  // useEffect(() => {
  //   // let arr = []

  //   // favouritesLocations.map((element, index) => {

  //   //   /* console.log(element) */

  //   //   fetch(`https://api.open-meteo.com/v1/forecast?latitude=${element.lat}&longitude=${element.lng}&daily=weathercode,sunrise,sunset&current_weather=true&timezone=auto`)
  //   //     .then(response => response.json())
  //   //     .then(data => { let merged = { element, data }; arr.push(merged); })
  //   // })

  //   // console.log("Arr: ", arr)
  //   // setFavLocComplete(arr)

  //   console.log("USEFFECT: ", favLocComplete);

  // }, [favLocComplete])


  /* console.log(data) */
  return (
    <div className="App">
      <Header getCurrentLocation={getCurrentLocation} getCurrentLocationWeather={getCurrentLocationWeather} currentLocationWeather={currentLocationWeather} loading={loadingLocalWeather} />

      <Hero
        citiesData={citiesData}
        handleInputOnChange={(e) => { setMemoSearch(e.target.value) }}
        searchInput={searchInput}
        data={citiesMemo}
        handleButtonClick={handleButtonClick}
        handleAddToFavourites={handleAddToFavourites}
        city={city}
        weatherData={weatherData}
        resetCityInput={() => { setMemoSearch("") }}
        urlApi={urlApi} /* getWeatherDesc={getWeatherDesc} */
        localTime={localTime}
      />



      {/*   <Search
        citiesData={citiesData}
        handleInputOnChange={(e) => { setMemoSearch(e.target.value) }}
        searchInput={searchInput}
        data={citiesMemo}
        handleButtonClick={handleButtonClick}
        handleAddToFavourites={handleAddToFavourites}
        city={city}
        weatherData={weatherData}
        resetCityInput={() => { setMemoSearch("") }}
        urlApi={urlApi} 
        localTime={localTime}
      />
           */ }


      {/* <Modal /> */}

      <FavouriteLocations favLocComplete={favLocComplete} favouritesLocations={favouritesLocations} handleButtonClick={handleButtonClick} handleClickScroll={handleClickScroll} handleRemoveFavourite={handleRemoveFavourite} />

      <div className="searchedWeather">
        {urlApi ? <SearchedWeather city={city.city} country={city.country} iso={city.iso2} weatherData={weatherData} lat={city.lat} lng={city.lng} localTime={localTime}/* weatherCode={weatherCode} */ /> : null}
      </div>

      {/* {favLocComplete.map(singleLocation => {

        return {<div>
          <h2>{singleLocation?.element.city}</h2>
          <h3>{singleLocation?.data.current_weather.temperature}</h3>
        </div>}

      })} */}
      {/* <Footer /> */}
    </div>
  );
}

export default App;
