import { useEffect, useState } from 'react'
import Flag from 'react-flagkit';
import { createClient } from 'pexels';


import Sun from "../images/Sun.svg"
import Clouds from "../images/Clouds 2.svg"
import CoudsWithSun from "../images/Clouds with sun.svg"
import CloudsWithRain from "../images/Clouds with rain.svg"
import CloudsWithSnow from "../images/Clouds with snow.svg"
import CloudsWithSnowDark from "../images/Clouds with snow dark.svg"
import CloudsWithThunder from "../images/Clouds with thunder.svg"

import "./SearchedWeatherSingle.sass"

const SearchedWeatherSingle = (props) => {
    const { country, city, weatherData, iso, localTime } = props

    const [weatherCodeDesc, setWeatherCodeDesc] = useState({ text: "", image: "" })

    /* const [weatherDesc, setWeatherDesc] = useState({ text: "", image: "" }) */
    const [photoWeatherCode, setPhotoWeatherCode] = useState([])
    const [photoCity, setPhotoCity] = useState([])

    const pexelsApiKey = "MBR5v3S5ysMpITt1DVQvWEh0J2odDGChlDgozWD7pgx6uHK5GuhXbdw2"
    const client = createClient(pexelsApiKey);


    const getWeatherDesc = (weatherCode) => {
        /* console.log("Weather code: ", weatherCode) */
        if (!weatherCode === null) {
            return console.log("Nothing loaded yet!" + " - " + "WeatherCode.js")
        }
        else {
            /* console.log(weatherCode) */
            let weatherCodeResult = "";
            switch (weatherCode) {
                case 0:
                    weatherCodeResult = { text: "Clear sky", image: Sun }
                    break;
                case 1:
                    weatherCodeResult = { text: "Mainly clear", image: Clouds }
                    break;
                case 2:
                    weatherCodeResult = { text: "Partly cloud", image: Clouds }
                    break;
                case 3:
                    weatherCodeResult = { text: "Overcast", image: CoudsWithSun }
                    break;
                case 45:
                    weatherCodeResult = { text: "Fog", image: Clouds }
                    break;
                case 48:
                    weatherCodeResult = { text: "Depositing rime fog", image: Clouds }
                    break;
                case 51:
                    weatherCodeResult = { text: "Light drizzle", image: CloudsWithRain }
                    break;
                case 53:
                    weatherCodeResult = { text: "Moderate drizzle", image: CloudsWithRain }
                    break;
                case 55:
                    weatherCodeResult = { text: "Dense intensity drizzle", image: CloudsWithRain }
                    break;
                case 56:
                    weatherCodeResult = { text: "Light freezing drizzle", image: CloudsWithSnow }
                    break;
                case 57:
                    weatherCodeResult = { text: "Dense intensity of freezing drizzle", image: CloudsWithSnow }
                    break;
                case 61:
                    weatherCodeResult = { text: "Slight rain", image: CloudsWithSnow }
                    break;
                case 63:
                    weatherCodeResult = { text: "Moderate rain", image: CloudsWithRain }
                    break;
                case 65:
                    weatherCodeResult = { text: "Heavy intensity of rain", image: CloudsWithRain }
                    break;
                case 66:
                    weatherCodeResult = { text: "Light freezing rain", image: CloudsWithSnow }
                    break;
                case 67:
                    weatherCodeResult = { text: "Heavy freezing rain", image: CloudsWithSnow }
                    break;
                case 71:
                    weatherCodeResult = { text: "Slight snow fall", image: CloudsWithSnowDark }
                    break;
                case 73:
                    weatherCodeResult = { text: "Moderate snow fall", image: CloudsWithSnowDark }
                    break;
                case 75:
                    weatherCodeResult = { text: "Heavy snow fall", image: CloudsWithSnowDark }
                    break;
                case 77:
                    weatherCodeResult = { text: "Snow grains", image: CloudsWithSnowDark }
                    break;
                case 80:
                    weatherCodeResult = { text: "Slight rain showers", image: CloudsWithRain }
                    break;
                case 81:
                    weatherCodeResult = { text: "Moderate rain showers", image: CloudsWithRain }
                    break;
                case 82:
                    weatherCodeResult = { text: "Violent rain showers", image: CloudsWithRain }
                    break;
                case 85:
                    weatherCodeResult = { text: "Slight snow showers", image: CloudsWithRain }
                    break;
                case 86:
                    weatherCodeResult = { text: "Heavy snow showers", image: CloudsWithRain }
                    break;
                case 95:
                    weatherCodeResult = { text: "Thunderstorm", image: CloudsWithThunder }
                    break;
                case 96:
                    weatherCodeResult = { text: "Thunderstorm with slight hail", image: CloudsWithThunder }
                    break;
                case 99:
                    weatherCodeResult = { text: "Thunderstorm with heavy hail", image: CloudsWithThunder }
                    break;
            }
            setWeatherCodeDesc(weatherCodeResult)
            return weatherCodeResult
        }
    }

    useEffect(() => {
        /*         console.log(weatherData?.current_weather.weathercode) */
        getWeatherDesc(weatherData?.current_weather.weathercode)
    }, [weatherData])

    useEffect(() => {
        {

            let query = `${weatherCodeDesc.text}`
            /* client.photos.search({ query, per_page: 1 }).then(photos => {
                console.log(photos);
                setPhotoWeatherCode(photos)
            }); */



            fetch(`https://api.pexels.com/v1//search?query=${query}&per_page=1`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    Authorization: pexelsApiKey,     //use the apikey you have generated
                }
            },)
                .then(response => response.json())
                .then(data => { console.log(data); setPhotoWeatherCode(data); })
                .catch(error => console.error(error))

        }

        {

            let query = `${city}`
            /* client.photos.search({ query, per_page: 1 }).then(photos => {
                setPhotoCity(photos)
            }); */

            fetch(`https://api.pexels.com/v1//search?query=${query}&per_page=1`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    Authorization: pexelsApiKey,     //use the apikey you have generated
                }
            },)
                .then(response => response.json())
                .then(data => { console.log(data); setPhotoCity(data); })
                .catch(error => console.error(error))

        }

    }, [weatherCodeDesc, city])


    /* backgroundImage: photoCity === "" ? `linear-gradient(135deg, rgba(65, 14, 204, 0.64) 0%, rgba(4, 61, 217, 0.64) 100%)` : `linear-gradient(135deg, rgba(65, 14, 204, 0.64) 0%, rgba(4, 61, 217, 0.64) 100%), url(${photoCity?.photos[0]?.src?.medium})`,
                    backgroundPosition: "bottom" */

    /* `linear-gradient(135deg, rgba(65, 14, 204, 0.64) 0%, rgba(4, 61, 217, 0.64) 100%)` :  */

    return (
        <div className='SearchedWeatherSingle'>
            <div className='flexboxItem background-gradient-purple box-shadow border-radius position-relative'
                style={
                    photoCity.photos ? {
                        backgroundImage: `linear-gradient(135deg, rgba(65, 14, 204, 0.64) 0%, rgba(4, 61, 217, 0.64) 100%), url(${photoCity?.photos[0]?.src?.medium})`,
                        backgroundPosition: "bottom"
                    } : {
                        backgroundImage: `linear-gradient(135deg, rgba(65, 14, 204, 0.64) 0%, rgba(4, 61, 217, 0.64) 100%)`,
                        backgroundPosition: "bottom"
                    }}
            >
                <div className='align-center'>
                    <h2>{city}</h2>
                    <h4>{<Flag country={iso} />} {country}</h4>
                    <h5>{localTime}</h5>
                </div>
            </div>

            <div className='flexboxItem background-gradient-purple box-shadow border-radius' style={
                photoWeatherCode.photos ? {
                    backgroundImage: `linear-gradient(135deg, rgba(65, 14, 204, 0.64) 0%, rgba(4, 61, 217, 0.64) 100%), url(${photoWeatherCode?.photos[0]?.src?.medium})`,
                    backgroundPosition: "bottom"
                } : {
                    backgroundImage: `linear-gradient(135deg, rgba(65, 14, 204, 0.64) 0%, rgba(4, 61, 217, 0.64) 100%)`,
                    backgroundPosition: "bottom"
                }}
            >
                {/*  backgroundImage: `linear-gradient(135deg, rgba(65, 14, 204, 0.64) 0%, rgba(4, 61, 217, 0.64) 100%), url(${photoWeatherCode.photos[0].src.small})` */}
                <div >
                    <h1>{weatherData?.current_weather.temperature} °C</h1>
                    {/* <img src={weatherDesc.image} alt={weatherDesc.text} /> */}
                    <img src={weatherCodeDesc.image} alt={weatherCodeDesc.text} />
                    <h4>{weatherCodeDesc.text}</h4>
                    {/* <h4>{weatherDesc.text}</h4> */}
                    <h4>L: {weatherData?.daily.temperature_2m_min[0]} °C |  H: {weatherData?.daily.temperature_2m_max[0]} °C   </h4>
                </div>
            </div>


        </div>
    )
}

export default SearchedWeatherSingle