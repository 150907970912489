import { useState } from 'react'
import Flag from 'react-flagkit';
import { Search, XLg } from 'react-bootstrap-icons';
import WeatherCode from './WeatherCode';

const FavouriteLocationSingle = (props) => {
    const { singleLocation } = props
    const [showRemoveButton, setShowRemoveButton] = useState(false);

    const handleMouseEnter = () => {
        setShowRemoveButton(true);
    };

    const handleMouseLeave = () => {
        setShowRemoveButton(false);
    };

    return (
        <div className='FavouriteLocationSingle align-center' onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <h2>{singleLocation.singleLocation.city}</h2>
            <h4>{<Flag country={singleLocation.singleLocation.iso2} />} {singleLocation.singleLocation.country}</h4>
            <h2>{singleLocation.data?.current_weather.temperature} °C</h2>
            <WeatherCode weatherCode={singleLocation.data?.current_weather.weathercode} />
            <h4>L: {singleLocation.data?.daily.temperature_2m_min[0]} °C |  H: {singleLocation.data?.daily.temperature_2m_max[0]} °C   </h4>
            <button className="btn btn-primary" onClick={() => { props.handleButtonClick(singleLocation.singleLocation); props.handleClickScroll() }}><Search style={{ marginRight: "8px" }} />Detail</button>
            {/* {isRemoveHidden ? <button className='remove-favourite-button btn btn-secondary'><XLg></XLg></button> : null} */}
            {showRemoveButton && (
                <button className='remove-favourite-button btn btn-secondary' onClick={() => props.handleRemoveFavourite(singleLocation)}><XLg></XLg></button>
            )}


        </div>
    )
}

export default FavouriteLocationSingle