import { useState, useEffect } from 'react';
import { ResponsiveContainer, AreaChart, CartesianGrid, XAxis, YAxis, Tooltip, Area } from 'recharts';
import "./Chart.sass"
import { ThermometerHalf, Thermometer, Umbrella, Wind, DropletHalf } from 'react-bootstrap-icons';


const Graph = (props) => {
    const [parsedWeatherData, setParsedWeatherData] = useState([])
    const [typeOfGraph, setTypeOfGraph] = useState("temperature_2m")


    //TODO předělat
    const renameKeys = (key) => {
        switch (key) {
            case "temperature_2m":
                return "Temperature"
            case "apparent_temperature":
                return "Apparent temperature"
            case "relativehumidity_2m":
                return "Relative humidity"
            case "precipitation":
                return "Precipitation"
            case "windspeed_10m":
                return "Wind speed"
        }
    }

    const formatDays = (isoDate) => {
        const date = new Date(isoDate);
        const dayName = date.toLocaleString("default", { weekday: "short" });
        let string = `${dayName} (${date.getDate()}.${date.getMonth() + 1} - ${date.getHours()}h)`
        /* console.log(string); */
        return string
    }

    const parseWeatherData = (weatherData, type) => {
        if (!weatherData) {
            /* return console.log("Nothing loaded yet!") */
        }
        else {
            let { time } = weatherData.hourly
            let arrayOfObjects = weatherData.hourly[type].map((elem, index) => {

                return {
                    [type]: elem,
                    time: formatDays(time[index])
                };
            });
            /* console.log("Chart", arrayOfObjects); */
            setParsedWeatherData(arrayOfObjects)
        }
    }

    useEffect(() => {
        parseWeatherData(props.weatherData, typeOfGraph)
        // console.log("Effect: ", props.weatherData)
    }, [props.weatherData, typeOfGraph])

    return (
        <div className='Chart'>
            <div className="question background-gradient-purple box-shadow border-radius">
                <div className="question-container">
                    <h2>{renameKeys(typeOfGraph)} chart</h2>

                    <ResponsiveContainer width="100%" height="100%">
                        <AreaChart /* width={730} height={250} */ data={parsedWeatherData}
                            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                            <defs>
                                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="60%" stopColor="rgba(217,63,26,0.8)" stopOpacity={0.8} />
                                    <stop offset="95%" stopColor="rgba(230,16,83, 0.8)" stopOpacity={0} />
                                </linearGradient>
                            </defs>
                            <XAxis dataKey="time" />
                            <YAxis />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <Area type="monotone" name={renameKeys(typeOfGraph)} dataKey={typeOfGraph} stroke="#1A0652" fillOpacity={1} fill="url(#colorUv)" />

                        </AreaChart>
                    </ResponsiveContainer>
                    <div className='buttons'>
                        <button className={`btn ${typeOfGraph === "temperature_2m" ? "btn-primary" : "btn-secondary"}`} onClick={() => setTypeOfGraph("temperature_2m")}><ThermometerHalf /> Temperature</button>
                        <button className={`btn ${typeOfGraph === "apparent_temperature" ? "btn-primary" : "btn-secondary"}`} onClick={() => setTypeOfGraph("apparent_temperature")}><Thermometer /> Apparent temperature</button>
                        <button className={`btn ${typeOfGraph === "relativehumidity_2m" ? "btn-primary" : "btn-secondary"}`} onClick={() => setTypeOfGraph("relativehumidity_2m")}><DropletHalf /> Humidity</button>
                        <button className={`btn ${typeOfGraph === "precipitation" ? "btn-primary" : "btn-secondary"}`} onClick={() => setTypeOfGraph("precipitation")}><Umbrella /> Precipitation</button>
                        <button className={`btn ${typeOfGraph === "windspeed_10m" ? "btn-primary" : "btn-secondary"}`} onClick={() => setTypeOfGraph("windspeed_10m")}><Wind /> Wind speed</button>
                    </div>

                </div>
            </div >
        </div >
    )
}

export default Graph