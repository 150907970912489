import "./FavouriteLocationSingle.sass"
import FavouriteLocationSingle from "./FavouriteLocationSingle";
import "./FavouriteLocations.sass"

const FavouriteLocations = (props) => {
    let chunkedArr = [];

    for (let i = 0; i < props.favLocComplete.length; i += 4) {
        const chunk = props.favLocComplete.slice(i, i + 4);
        chunkedArr.push(chunk);
    }

    return (
        < div className='FavouriteLocations container' id="favouriteLocations" >
            {

                chunkedArr.length > 0 ?
                    chunkedArr.map((row, index) => {
                        return <div className="d-flex items" key={index}>
                            {row.map((singleLocation, index) => {
                                return <FavouriteLocationSingle key={index} singleLocation={singleLocation} handleButtonClick={props.handleButtonClick} handleClickScroll={props.handleClickScroll} handleRemoveFavourite={props.handleRemoveFavourite} />
                            })}

                        </div>
                    }) : null
            }
        </div >
    )
}

export default FavouriteLocations