import React from 'react'
import { useState, useEffect } from 'react'
import PrecipitationSingle from './PrecipitationSingle'
import "./Precipitation.sass"


const Precipitation = (props) => {
    const { weatherData } = props

    const [highestTemp, setHighestTemp] = useState([])
    const [days, setDays] = useState([])
    const [lowestTemp, setLowestTemp] = useState([])
    const [weatherCodes, setWeatherCodes] = useState([])

    const [parsedData, setParsedData] = useState([])

    /* console.log(weatherData) */



    const getDays = () => {
        const today = new Date();
        const tomorrow = new Date()
        let days = []

        for (let index = 0; index < 5; index++) {
            tomorrow.setDate(today.getDate() + (index + 1))
            days.push(tomorrow.toLocaleDateString("en-US", { weekday: "short" }))
        }
        setDays(days)
        /* return { days: days } */

    }

    useEffect(() => {

        /* console.log(weatherData) */

        getDays()


        const parsedWeatherData = days.map((day, index) => ({
            highestTemperature: weatherData?.daily.temperature_2m_max[index + 1],
            lowestTemperature: weatherData?.daily.temperature_2m_min[index + 1],
            weatherCode: weatherData?.daily.weathercode[index + 1],
            days: day
        }));

        setParsedData(parsedWeatherData)

    }, [props.weatherData])

    useEffect(() => {

    }, [parsedData])

    /* console.log(parsedData) */




    return (
        <div className='Precipitation'>
            <div className='background-gradient-purple box-shadow border-radius position-relative wrapper'>

                <div className='d-flex precipitation-items'>
                    {

                        parsedData === "" ? null : parsedData.map((element, index) => {

                            return <PrecipitationSingle length={index} key={index} day={element.days} weatherCode={element.weatherCode} highestTemperature={element.highestTemperature} lowestTemperature={element.lowestTemperature} />
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Precipitation