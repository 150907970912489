import React from 'react'
import Flag from 'react-flagkit';
import "./SearchItem.sass"
import { ArrowRight, Star } from 'react-bootstrap-icons';

const SearchItem = (props) => {

    const handleClickScroll = () => {
        const element = document.querySelector(".searchedWeather")
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            setTimeout(() => {
                element.scrollIntoView({ behavior: 'smooth' })
            }, 50)
        }
    };

    return (
        <div className="SearchItem list border-bottom">
            <div className="d-flex ml-3" onClick={(event) => { props.handleButtonClick(props.element); handleClickScroll(); props.resetCityInput(event) }}>
                <Flag country={props.element.iso2} />
                <span>{props.element.city}</span>
                <small>{props.element.country}</small>
            </div>

            <div className="d-flex ml-3">
                <button className={`btn-square`} onClick={(event) => { props.handleButtonClick(props.element); handleClickScroll(); props.resetCityInput(event) }} style={{ width: "48px", height: "48px" }}><ArrowRight style={{ /* fill: "purple", */ verticalAlign: "baseline", height: "24px", width: "24px" }} /></button>

                <button className={`btn-square`} onClick={(event) => { props.handleAddToFavourites(props.element) }} style={{ width: "48px", height: "48px" }}><Star style={{ /* fill: "purple", */ verticalAlign: "baseline", height: "24px", width: "24px" }} /></button>
            </div>
        </div>
    )
}

export default SearchItem