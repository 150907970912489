import React from 'react'
import "./GoogleMaps.sass"
import { Map, KeyboardControl, MouseControl, ZoomControl, CompassControl, SyncControl } from 'react-mapycz'


const GoogleMaps = (props) => {
    const { lat, lng } = props

    return (
        <div className='GoogleMaps box-shadow'>
            {/* <iframe title='Google Maps' src={url} style={{}} /> */}
            <Map height="500px" center={{ lat: lat, lng: lng }} zoom={8}>
                <KeyboardControl />
                <ZoomControl />
                <MouseControl zoom={true} pan={true} wheel={true} />
                <CompassControl right={10} top={50} />
                <SyncControl />
                {/* <MarkerLayer><Marker coords={{ lat: lat, lng: lng }} /></MarkerLayer> */}
            </Map>

        </div>
    )
}

export default GoogleMaps