import React from 'react'
import WeatherCode from './WeatherCode'
import "./PrecipitationSingle.sass"

const PrecipitationSingle = (props) => {
    const { day, weatherCode, highestTemperature, lowestTemperature, length } = props
    return (
        <div className='PrecipitationSingle' style={{ borderRight: length != 4 ? '1px solid rgba(0,0,0,0.2)' : 'none' }}>
            <h3>{day.toUpperCase()}</h3>
            <WeatherCode weatherCode={weatherCode} className="WeatherCodeComponent" />
            <h5>L: {lowestTemperature} °C | H: {highestTemperature} °C </h5>
        </div>
    )
}

export default PrecipitationSingle