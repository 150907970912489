import "./SearchInput.sass"
import { Search } from 'react-bootstrap-icons';

const SearchInput = (props) => {
    return (
        <div className="SearchInput">
            <input type="text" placeholder='Czechia, Prague, Bratislava, London...' className="form-control" name="cityInput" onChange={(event) => props.handleInputOnChange(event)} value={props.searchInput.value} />
            <Search className="input-box-icon" />
        </div>
    )
}

export default SearchInput