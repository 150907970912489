import { useState, useEffect } from "react"
import Sun from "../images/Sun.svg"
import Clouds from "../images/Clouds 2.svg"
import CoudsWithSun from "../images/Clouds with sun.svg"
import CloudsWithRain from "../images/Clouds with rain.svg"
import CloudsWithSnow from "../images/Clouds with snow.svg"
import CloudsWithSnowDark from "../images/Clouds with snow dark.svg"
import CloudsWithThunder from "../images/Clouds with thunder.svg"
import "./WeatherCode.sass"

const WeatherCode = (props) => {

    const [weatherCodeDesc, setWeatherCodeDesc] = useState({ text: "", image: "" })


    const getWeatherDesc = (weatherCode) => {
        /* console.log("Weather code: ", weatherCode) */
        if (!weatherCode === null) {
            return console.log("Nothing loaded yet!" + " - " + "WeatherCode.js")
        }
        else {
            /* console.log(weatherCode) */
            let weatherCodeResult = "";
            switch (weatherCode) {
                case 0:
                    weatherCodeResult = { text: "Clear sky", image: Sun }
                    break;
                case 1:
                    weatherCodeResult = { text: "Mainly clear", image: Clouds }
                    break;
                case 2:
                    weatherCodeResult = { text: "Partly cloud", image: Clouds }
                    break;
                case 3:
                    weatherCodeResult = { text: "Overcast", image: CoudsWithSun }
                    break;
                case 45:
                    weatherCodeResult = { text: "Fog", image: Clouds }
                    break;
                case 48:
                    weatherCodeResult = { text: "Depositing rime fog", image: Clouds }
                    break;
                case 51:
                    weatherCodeResult = { text: "Light drizzle", image: CloudsWithRain }
                    break;
                case 53:
                    weatherCodeResult = { text: "Moderate drizzle", image: CloudsWithRain }
                    break;
                case 55:
                    weatherCodeResult = { text: "Dense intensity drizzle", image: CloudsWithRain }
                    break;
                case 56:
                    weatherCodeResult = { text: "Light freezing drizzle", image: CloudsWithSnow }
                    break;
                case 57:
                    weatherCodeResult = { text: "Dense intensity of freezing drizzle", image: CloudsWithSnow }
                    break;
                case 61:
                    weatherCodeResult = { text: "Slight rain", image: CloudsWithSnow }
                    break;
                case 63:
                    weatherCodeResult = { text: "Moderate rain", image: CloudsWithRain }
                    break;
                case 65:
                    weatherCodeResult = { text: "Heavy intensity of rain", image: CloudsWithRain }
                    break;
                case 66:
                    weatherCodeResult = { text: "Light freezing rain", image: CloudsWithSnow }
                    break;
                case 67:
                    weatherCodeResult = { text: "Heavy freezing rain", image: CloudsWithSnow }
                    break;
                case 71:
                    weatherCodeResult = { text: "Slight snow fall", image: CloudsWithSnowDark }
                    break;
                case 73:
                    weatherCodeResult = { text: "Moderate snow fall", image: CloudsWithSnowDark }
                    break;
                case 75:
                    weatherCodeResult = { text: "Heavy snow fall", image: CloudsWithSnowDark }
                    break;
                case 77:
                    weatherCodeResult = { text: "Snow grains", image: CloudsWithSnowDark }
                    break;
                case 80:
                    weatherCodeResult = { text: "Slight rain showers", image: CloudsWithRain }
                    break;
                case 81:
                    weatherCodeResult = { text: "Moderate rain showers", image: CloudsWithRain }
                    break;
                case 82:
                    weatherCodeResult = { text: "Violent rain showers", image: CloudsWithRain }
                    break;
                case 85:
                    weatherCodeResult = { text: "Slight snow showers", image: CloudsWithRain }
                    break;
                case 86:
                    weatherCodeResult = { text: "Heavy snow showers", image: CloudsWithRain }
                    break;
                case 95:
                    weatherCodeResult = { text: "Thunderstorm", image: CloudsWithThunder }
                    break;
                case 96:
                    weatherCodeResult = { text: "Thunderstorm with slight hail", image: CloudsWithThunder }
                    break;
                case 99:
                    weatherCodeResult = { text: "Thunderstorm with heavy hail", image: CloudsWithThunder }
                    break;
            }
            setWeatherCodeDesc(weatherCodeResult)
            return weatherCodeResult
        }
    }

    useEffect(() => {
        getWeatherDesc(props.weatherCode)
    }, [props.weatherCode])



    return (
        <>
            <img src={weatherCodeDesc.image} alt={weatherCodeDesc.text} style={{ height: "80px" }} />
            <h4>{weatherCodeDesc.text}</h4>
        </>
    )
}

export default WeatherCode