import "./SearchList.sass"
import SearchItem from './SearchItem';

const SearchList = (props) => {
    return (<div className="SearchList">
        {props.data.map((element, index) => {

            return <SearchItem
                key={index}
                element={element}
                handleButtonClick={props.handleButtonClick}
                handleAddToFavourites={props.handleAddToFavourites}
                resetCityInput={props.resetCityInput} />

        })}
    </div>
    )
}

export default SearchList