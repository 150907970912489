import Graph from './Graph'
import GoogleMaps from './GoogleMaps';
import Precipitation from './Precipitation';
import "bootstrap/dist/css/bootstrap.min.css"
import "./SearchedWeather.sass"

import SearchedWeatherSingle from './SearchedWeatherSingle';


const SearchedWeather = (props) => {
    const { lat, lng, country, city, weatherData, iso, localTime } = props

    return (
        <div >
            <div className='container mt-5' >
                <SearchedWeatherSingle
                    city={city}
                    iso={iso}
                    country={country}
                    weatherData={weatherData}
                    /* weatherDesc={weatherDesc} */
                    localTime={localTime}
                />
                <Precipitation weatherData={weatherData} />
                <Graph weatherData={weatherData} />
                <GoogleMaps lat={lat} lng={lng} />
            </div >
        </div >
    )
}

export default SearchedWeather